import {
    Box,
    Button,
    Container,
    Pagination,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { getBroadCastMessage } from "../../controller/broadcastMessageController";
import { Link, useNavigate } from "react-router-dom";
import { handleControllerResultException } from "../../utils/handleException";
import { blue, grey } from "@mui/material/colors";
import BroadcastMessageDetailModal from "./BroadcastMessageDetailModal";
import { Add, BorderColor } from "@mui/icons-material";

export default function BroadCastMessages() {
    const [broadcastMessageList, setBroadcastMessageList] = useState(messageListType);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTab, setCurrentTab] = useState(0);
    const [showMessageDetailsModal, setShowMessageDetailsModal] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(initialMessage);
    const tabList = useRef(["All", "Active", "InActive", "Request"]);
    const navigate = useNavigate();

    const tabToMessageType = {
        "All": "All",
        "Active": "Active",
        "InActive": "Old",
        "Request": "Request"
    };

    const handleMessageList = useCallback(
        async (messageType = "All", page = 1) => {
            const res = await getBroadCastMessage(messageType, page);
            if (res[0] !== null) {
                setBroadcastMessageList(res[0].results);
                setTotalPages(Math.ceil(res[0].count / 10));
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    useEffect(() => {
        handleMessageList(tabToMessageType[tabList.current[currentTab]], currentPage);
    }, [currentPage, currentTab, handleMessageList]);

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ margin: "5rem auto" }}>
                <Typography sx={{ fontSize: "1.4rem", marginBottom: "1rem", fontWeight: "500", color: grey[800] }}>Broadcast list</Typography>

                <Box
                    marginBottom={"2rem"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    flexWrap="wrap"
                >
                    <Tabs
                        value={currentTab}
                        onChange={(_e, value) => {
                            setCurrentTab(value);
                            setCurrentPage(1); // Reset to page 1 on tab change
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {tabList.current.map((data, index) => (
                            <Tab label={data} key={index} />
                        ))}
                    </Tabs>
                    <Link to={"/broadcast-form"}>
                        <Button variant="contained" sx={{ marginTop: { xs: '1rem', sm: '0' } }}>
                            <Add />
                            New Broadcast
                        </Button>
                    </Link>
                </Box>

                {broadcastMessageList.length > 0 ? (
                    <TableContainer component={Paper} elevation={2}>
                        <Table sx={{ width: "100%" }}>
                            <TableHead sx={{ backgroundColor: blue[700] }}>
                                <TableRow>
                                    <TableCell sx={{ color: "white" }}>
                                        Sender
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        Message topic
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        Date
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        Details
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {broadcastMessageList.map(message => (
                                    <TableRow key={message.id}>
                                        <TableCell>
                                            <Link to={`/broadcast-form/${message.id}`}>
                                                <button>
                                                    <BorderColor color="primary" sx={{ marginRight: "10px" }} fontSize="small" />
                                                </button></Link>
                                            {message.senderEmail}
                                        </TableCell>
                                        <TableCell>{message.header}</TableCell>
                                        <TableCell>{message.date}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    setSelectedMessage(message);
                                                    setShowMessageDetailsModal(true);
                                                }}
                                            >
                                                Details
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="h5" textAlign={"center"}>
                        No message found
                    </Typography>
                )}
                {broadcastMessageList.length > 0 && (
                    <Box
                        display={"flex"} justifyContent={"center"} margin={"2rem 0"}
                    >
                        <Pagination
                            color="primary"
                            count={totalPages}
                            page={currentPage}
                            onChange={(_e, value) => {
                                setCurrentPage(value);
                            }}
                            sx={{ marginTop: "2rem" }}
                        />
                    </Box>
                )}
            </Container>
            <BroadcastMessageDetailModal
                open={showMessageDetailsModal}
                onClose={() => setShowMessageDetailsModal(false)}
                message={selectedMessage}
                setMessageList={setBroadcastMessageList}
            />
        </Box>
    );
}

/**
 * @type {import('../../models/BroadcastMessageModel').BroadcastMessageModel[]}
 */
const messageListType = [];

/**
 * @type {import("../../models/BroadcastMessageModel").BroadcastMessageModel}
 */

const initialMessage = {
    id: 0,
    senderId: 0,
    senderEmail: "",
    header: "",
    message: "",
    reciverEmailList: [],
    reciverIdList: [],
    date: "",
    isActive: false,
    isAdminApproved: false,
};
