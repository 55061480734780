import {
    Avatar,
    Box,
    Button,
    Container,
    Pagination,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/Header";
import { Add, Edit, Group } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { getInsituteList } from "../../controller/instituteController";
import { handleControllerResultException } from "../../utils/handleException";
import { blue, grey } from "@mui/material/colors";

/**
 * @type {import("../../models/InstituteModel").InstituteModel[]}
 */
const instituteListType = [];

export default function Institute() {
    const [insitituteList, setInstituteList] = useState(instituteListType);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [fetchingList, setFetchingList] = useState(false);
    const navigate = useNavigate();

    const handleInstituteList = useCallback(
        async currentPage => {
            setFetchingList(true);
            const res = await getInsituteList(currentPage);
            setFetchingList(false);
            if (res[0] !== null) {
                setTotalPages(Math.ceil(res[0].count / 10));
                setInstituteList(res[0].results);
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    useEffect(() => {
        handleInstituteList(currentPage);
    }, [handleInstituteList, currentPage]);

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ marginX: "auto", paddingY: "5rem" }}>

                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography sx={{ fontSize: "1.7rem", marginBottom: "1.6rem", fontWeight: "500", color: grey[800] }}>Institutes</Typography>
                    <Link to={"/institute-form"}>
                        <Button variant="contained">
                            <Add
                                fontSize="small"
                                sx={{ marginRight: "10px" }}
                            />
                            Add Institute
                        </Button>
                    </Link>
                </Box>
                {!fetchingList ? (
                    <TableContainer
                        component={Paper}
                        elevation={2}
                        sx={{
                            marginTop: "5rem",
                            minWidth: 650,
                            minHeight: "500px",
                        }}
                    >
                        <Table>
                            <TableHead sx={{ backgroundColor: blue[700] }}>
                                <TableRow>
                                    <TableCell sx={{ color: "white" }}>
                                        Institute name
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        Contact info
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        Address
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        Students
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {insitituteList.length > 0 &&
                                    insitituteList.map(data => (
                                        <TableRow key={data.id}>
                                            <TableCell>
                                                <Box
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                >
                                                    <Link
                                                        to={`/institute-form/${data.id}`}
                                                    >
                                                        <Button>
                                                            <Edit fontSize="small" />
                                                        </Button>
                                                    </Link>
                                                    <Avatar
                                                        src={
                                                            data.insituteLogoUrl
                                                        }
                                                        alt={data.instituteName}
                                                    />
                                                    <Typography
                                                        fontSize={"15px"}
                                                        marginLeft={"10px"}
                                                    >
                                                        {data.instituteName}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                {data.email &&
                                                    `Email - ${data.email}`}{" "}
                                                <br />{" "}
                                                {data.phoneNumber &&
                                                    `Ph - ${data.phoneNumber}`}
                                            </TableCell>
                                            <TableCell>
                                                {data.city},{data.state},
                                                {data.country},{data.pinCode}
                                            </TableCell>
                                            <TableCell>
                                                <Link
                                                    to={`/institute-students/${data.id}`}
                                                >
                                                    <Button>
                                                        <Group />
                                                    </Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Skeleton
                        width={"650px"}
                        height={"500px"}
                        sx={{ marginTop: "5rem" }}
                        variant="rectangular"
                    />
                )}
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    marginTop={"2rem"}
                >
                    <Pagination
                        count={totalPages}
                        color="primary"
                        onChange={(_e, page) => setCurrentPage(page)}
                    />
                </Box>
            </Container>
        </Box>
    );
}
