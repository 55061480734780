import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import DetailCard from "../../components/DetailCard/DetailCard";

const cookies = new Cookies();

const WorkExperienceList = () => {
    const [workExData, setWorkExData] = useState([]);

    const getWorkexData = () => {
        axios
            .get(
                `hri_admin/bulk-resume-detail/${localStorage.getItem(
                    "Un-registered User"
                )}`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then(res => {
                setWorkExData(res.data.work_exp_list);
            })
            .catch(err => {
                console.log(
                    "Error occurred fetching Work Experience Data: ",
                    err
                );
            });
    };

    useEffect(() => {
        getWorkexData();
    }, []);

    return (
        <div className="rounded-lg shadow-md p-6 m-6">
            <p className="text-2xl text-[#0865B6] font-bold mb-6">
                Work Experience
            </p>
            <div className="space-y-8">
                {workExData &&
                    workExData.map((experience) => (
                        <DetailCard
                            key={experience.id}
                            company={experience.company}
                            position={experience.position}
                            startYear={experience.start_year}
                            endYear={experience.end_year}
                            description={experience.description}
                        />
                    ))}
            </div>
        </div>
    );
};

export default WorkExperienceList;
