import React from "react";
import { FaBuilding, FaCalendarAlt, FaBriefcase } from "react-icons/fa";

const DetailCard = ({ company, position, startYear, endYear, description }) => {
    return (
        <div className="bg-white rounded-lg p-5 shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-xl">
            <div className="mb-2 flex items-center">
                <FaBuilding className="text-[#0865B6] mr-2 flex-shrink-0" />
                <p className="text-xl font-bold text-gray-700 mt-3">
                    {company || "NA"}
                </p>
            </div>
            <div className="mb-2 flex items-center">
                <FaBriefcase className="text-[#0865B6] mr-2 flex-shrink-0" />
                <p className="text-lg text-gray-600 mt-3">
                    {position || "NA"}
                </p>
            </div>
            <div className="mb-4 flex items-center">
                <FaCalendarAlt className="text-[#0865B6] mr-2 flex-shrink-0" />
                <p className="text-md text-gray-500 mt-3">
                    {startYear || "NA"} - {endYear || "NA"}
                </p>
            </div>
            <p className="text-xl text-gray-700 mb-2">Description</p>
            <ul 
                className="pl-5 text-lg text-gray-600 space-y-1"
                style={{ listStyleType: "disc" }}
            >
                {description && description.length > 0 ? (
                    description.map((item, index) => (
                        <li key={index} style={{ listStyleType: "disc" }}>
                            {item}
                        </li>
                    ))
                ) : (
                    <li>No description available</li>
                )}
            </ul>
        </div>
    );
};

export default DetailCard;
