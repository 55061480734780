import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { handleControllerResultException } from "../../utils/handleException";
import { blue, grey } from "@mui/material/colors";
import { AiFillEye } from "react-icons/ai";
import { RiShareForward2Fill } from "react-icons/ri";
import ApplicationStatus from "./ApplicationStatus";
import SkillsModal from "../Applications/SkillsModel";
import { BarChart } from "@mui/icons-material";
import {
    getApplicantsList,
    getApplicationFilterList,
} from "../../controller/UserApplicationController";
import ApplicationShareModal from "./ApplicationShareModal/ApplicationShareModal";
import SortApplications from "./SortApplications";
import noApplication from "../../Images/empty_states/no-application.png"

export default function Applications() {
    const [applicantList, setApplicantList] = useState(applicantListType);
    const [showSkillsModal, setShowSkillsModal] = useState(false);
    const [selectedSkills, setSelectedSkills] = useState(skillListType);
    const [selectedApplication, setSelectedApplication] = useState(
        jobApplicationDetailsType
    );
    const [showApplicationShareModal, setShowApplicationShareModal] =
        useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [jobName, setJobName] = useState("");
    const [education, setEducation] = useState("");
    const [specialization, setSpecialization] = useState("");
    const [status, setStatus] = useState("")
    /**
     * "IsSearched" is used to check if a search has occurred or not. This is because there are two APIs for fetching items.
     *  The first API is for retrieving all items, and the second one is for obtaining filtered items. 
     * This state variable helps in tracking which API to use. 
     * If the state is true, then the filtered API should be used; if it's false, then the other API should be used.
     */
    const [isSearched, setIsSearched] = useState(false);
    const [fetchingList, setFetchingList] = useState(false)
    const navigate = useNavigate();

    const handleApplicantList = useCallback(
        async (page = 1) => {
            setFetchingList(true)
            const res = await getApplicantsList(page);
            setFetchingList(false)
            if (res[0] !== null) {
                setApplicantList(res[0].results);
                setTotalPages(Math.ceil(res[0].count / 5));
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    async function handleFilter({ jobName, education, specialization, status, page = 1 }) {
        const res = await getApplicationFilterList({
            jobName,
            education,
            specialization,
            status,
            page
        });
        if (res[0] !== null) {
            setApplicantList(res[0].results);
            setTotalPages(Math.ceil(res[0].count / 5));
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }

    useEffect(() => {
        handleApplicantList();
    }, [handleApplicantList]);

    return (
        <Box>
            <Header />
            <Container maxWidth="xl" sx={{ marginY: "5rem" }}>
                <Typography sx={{ fontSize: "1.4rem", marginBottom: "1.6rem", fontWeight: "500", color: grey[800] }}>Applications</Typography>
                <Box marginBottom={"1rem"}>
                    <SortApplications
                        filterFunc={() => handleFilter({ education, jobName, specialization, status })}
                        setIsSearched={setIsSearched}
                        jobName={jobName}
                        setJobName={setJobName}
                        specialization={specialization}
                        setSpecialization={setSpecialization}
                        education={education}
                        setEducation={setEducation}
                        status={status}
                        setStatus={setStatus}
                        setCurrentPage={setCurrentPage}
                        clearFunc={() => {
                            handleApplicantList()
                            setCurrentPage(1)
                        }}
                    />
                </Box>
                {!fetchingList ? (applicantList.length > 0 ? (
                    <div>
                        <TableContainer component={Paper} elevation={2}>
                            <Table sx={{ width: "100%" }}>
                                <TableHead sx={{ backgroundColor: blue[700] }}>
                                    <TableRow>
                                        <TableCell sx={{ color: "white" }}>
                                            Applicants
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Job
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Specialization
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Shared at
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Resume
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Details
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Status
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>
                                            Share
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {applicantList.map(data => (
                                        <TableRow key={data.id}>
                                            <TableCell>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <button
                                                        onClick={() => {
                                                            setSelectedSkills(
                                                                data.skills
                                                            );
                                                            setShowSkillsModal(true);
                                                        }}
                                                    >
                                                        <Tooltip title="Skills">
                                                            <BarChart />
                                                        </Tooltip>
                                                    </button>
                                                    <Avatar
                                                        src={data.applicantProfilePic}
                                                        alt={data.applicantFirstName}
                                                        sx={{ marginX: "5px" }}
                                                    />
                                                    <Typography>
                                                        {data.applicantFirstName}
                                                        {data.applicantLastName}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{data.jobName}</TableCell>
                                            <TableCell>
                                                {data.applicantSpecialization}
                                            </TableCell>
                                            <TableCell>{data.appliedDate}</TableCell>
                                            <TableCell>
                                                <Box
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                >
                                                    <AiFillEye
                                                        style={{ marginRight: "5px" }}
                                                        size={"15px"}
                                                    />
                                                    <a
                                                        href={data.resumeFileLink}
                                                        target="_blank" rel="noreferrer"
                                                    >
                                                        {data.resumeFileName}
                                                    </a>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Link
                                                    to={`/applications-details/${data.applicantId}`}
                                                >
                                                    <Button variant="outlined">
                                                        Details
                                                    </Button>
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <ApplicationStatus
                                                    applicationId={data.id}
                                                    currentStatus={data.status}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <button
                                                    onClick={() => {
                                                        setSelectedApplication(data);
                                                        setShowApplicationShareModal(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <RiShareForward2Fill
                                                        size={"1.3rem"}
                                                    />
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {totalPages > 1 && (
                            <Box
                                marginTop={"2rem"}
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={(_e, value) => {
                                        isSearched
                                            ? handleFilter({
                                                jobName,
                                                education,
                                                specialization,
                                                status,
                                                page: value
                                            })
                                            : handleApplicantList(value);
                                        setCurrentPage(value);
                                    }}
                                    color="primary"
                                />
                            </Box>
                        )}
                    </div>

                ) : <Box display={"flex"} justifyContent={"center"} marginTop={2}>
                    <Box>
                        <img
                            src={noApplication}
                            alt="no webinar"
                            height={"200px"}
                            width={"200px"}
                        />
                        <Typography variant="h6" textAlign={"center"} marginTop={"5px"}>No Data Found</Typography>
                    </Box>
                </Box>
                ) : <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <CircularProgress />
                </Box>}

            </Container>

            <SkillsModal
                open={showSkillsModal}
                onClose={() => setShowSkillsModal(false)}
                skillList={selectedSkills}
            />
            <ApplicationShareModal
                open={showApplicationShareModal}
                onClose={() => setShowApplicationShareModal(false)}
                applicantId={selectedApplication.applicantId}
                applicationId={selectedApplication.id}
                positionId={selectedApplication.positionId}
            />
        </Box>
    );
}

/**
 * @type {import('../../models/JobApplicationModel').JobApplicationModel[]}
 */

const applicantListType = [];

/**
 * @type {import("../../models/UserApplicationModel").UserSkillModel[]}
 *
 */

const skillListType = [];

/**
 * @type {import("../../models/JobApplicationModel").JobApplicationModel}
 */

const jobApplicationDetailsType = {
    id: 0,
    applicantFirstName: "",
    applicantLastName: "",
    applicantId: 0,
    applicantProfilePic: "",
    applicantSpecialization: "",
    appliedDate: "",
    assessmentId: 0,
    isCompanyBookmark: false,
    isAssessment: false,
    jobName: "",
    positionId: 0,
    resumeFileLink: "",
    resumeFileName: "",
    status: "In-Review",
    skills: [],
};
