import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import DetailCard from "../../components/DetailCard/DetailCard";

const cookies = new Cookies();

const Project = () => {
    const [projectData, setProjectData] = useState([]);

    const getProjectData = () => {
        axios
            .get(
                `hri_admin/bulk-resume-detail/${localStorage.getItem(
                    "Un-registered User"
                )}`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then(res => {
                setProjectData(res.data.personal_project_list);
            })
            .catch(err => {
                console.log(
                    "Error occurred fetching Work project Data: ",
                    err
                );
            });
    };

    useEffect(() => {
        getProjectData();
    }, []);

    return (
        <div className="rounded-lg shadow-md p-6 m-6">
            <p className="text-2xl text-[#0865B6] font-bold mb-6">
                Project List
            </p>
            <div className="space-y-8">
                {projectData &&
                    projectData.map((project) => (
                        <DetailCard
                            key={project.id}
                            company={project.project_name}
                            position={project.technology_used}
                            startYear={project.start_year}
                            endYear={project.end_year}
                            description={project.description}
                        />
                    ))}
            </div>
        </div>
    );
};

export default Project;
